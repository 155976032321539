import {
  Box,
  Container,
  Fade,
  Flex,
  Spinner,
  type StyleProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import TopDesktopNavBar from '@src/components/TopDesktopNavBar';
import TopMobileNavBar, {
  type Props as TopMobileNavBarProps,
} from '@src/components/TopMobileNavBar';
import { AppStore } from '@src/stores';
import { type FC, type ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BottomNavBar from './BottomNavBar';

interface Props {
  topMobileNavBarProps?: TopMobileNavBarProps;
  smallNavBar?: ReactNode;
  content: ReactNode;
  bottomCta?: ReactNode;
  backgroundColor?: StyleProps['backgroundColor'];
  showBottomNavBar?: boolean;
  isFullWidth?: boolean;
}

const Page: FC<Props> = ({
  topMobileNavBarProps,
  bottomCta = null,
  content,
  showBottomNavBar,
  backgroundColor = 'neutral.bg.0',
  isFullWidth = false,
}) => {
  const navBar = useBreakpointValue(
    {
      base: topMobileNavBarProps ? (
        <TopMobileNavBar {...topMobileNavBarProps} />
      ) : null,
      md: <TopDesktopNavBar />,
    },
    { ssr: false },
  );

  const bottomNavBar = useBreakpointValue(
    {
      base: showBottomNavBar && <BottomNavBar />,
      md: null,
    },
    { ssr: false },
  );
  const showPageLoading = AppStore.useState((s) => s.showPageLoading);

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: necessary. Not sure if ref is though
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [location, ref]);

  const container = (
    <Box
      px={isFullWidth ? 0 : 'regular'}
      pt={{ base: 'regular', md: 'mega' }}
      pb="hecto"
      flex={{ base: 1, md: 'none' }}
      id="main"
      as="main"
    >
      <Container
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
        pt={navBar ? 0 : 'env(safe-area-inset-top)'}
        pb={showBottomNavBar ? 0 : 'env(safe-area-inset-bottom)'}
        minHeight="100%"
        display="flex"
        flexDirection="column"
        maxWidth={isFullWidth ? 'auto' : undefined}
      >
        <Box flex={1} position="relative">
          {content}
        </Box>

        {bottomCta}
      </Container>
    </Box>
  );

  return (
    <>
      <Flex
        position="fixed" // Fixed instead of absolute is necessary to prevent a bug on Android. Pulling up the keyboard squahes the page, which is fine. But, after dismissing the keyboard, the page remains squashed.
        width="100%"
        height="100%"
        minHeight="100%"
        overflow="auto" // necessary, otherwise "height: 100%" causes sticky navbar to disappear if content extends beyond a page.
        direction="column"
        backgroundColor={backgroundColor}
        ref={ref}
      >
        {navBar}

        {showPageLoading && (
          <Box
            position="absolute"
            top="44%"
            textAlign="center"
            width="100%"
            zIndex={1}
          >
            <Fade
              in
              transition={{
                enter: { duration: 0.4, delay: 0.3 },
              }}
            >
              <Spinner size="lg" />
            </Fade>
          </Box>
        )}

        <Flex
          direction="column"
          height="100%"
          transition={showPageLoading ? undefined : 'opacity 0.5s ease'}
          opacity={showPageLoading ? 0 : 1}
        >
          {container}
          {bottomNavBar}
        </Flex>
      </Flex>

      {/* White bars on top and bottom on mobile. */}
      <Box
        zIndex={999}
        height="env(safe-area-inset-top)"
        width="100vw"
        position="fixed"
        top={0}
        left={0}
        backgroundColor="neutral.bg.0"
      />
      <Box
        zIndex={999}
        height="env(safe-area-inset-bottom)"
        width="100vw"
        position="fixed"
        bottom={0}
        left={0}
        backgroundColor="neutral.bg.0"
      />
    </>
  );
};

export default Page;
