import { selectAnatomy } from '@chakra-ui/anatomy';
import {
  type ChakraTheme,
  createMultiStyleConfigHelpers,
  theme,
} from '@chakra-ui/react';
import space from './space';
import textStyles, { poppinsBaseStyles } from './textStyles';

const { definePartsStyle } = createMultiStyleConfigHelpers(selectAnatomy.keys);

// For multipart components, you need to find the different part names.
// For example: https://github.com/chakra-ui/chakra-ui/blob/176fa4152fba09c87902e892c725c64666366067/packages/theme/src/components/slider.ts#L92
//
// Warning! Broadly, if you set text styles in here, you won’t be able to override them.
// If components have default text styles defined by Chakra, it’ll be annoying to override as well.
// Potentially better to define defaults as global CSS.
//
// For more context:
// https://github.com/chakra-ui/chakra-ui/issues/3287
// https://github.com/chakra-ui/chakra-ui/discussions/3612
const components: ChakraTheme['components'] = {
  Heading: {
    defaultProps: {
      size: 'throwaway to prevent changes on resize',
    },
  },
  Link: {
    baseStyle: {
      fontWeight: 600,
      color: 'action.primary.base',
    },
    variants: {
      secondary: {
        color: 'action.secondary',
        fontWeight: 600,
      },
    },
  },
  FormError: {
    baseStyle: {
      text: {
        color: 'danger',
      },
    },
  },
  Input: {
    baseStyle: {
      _placeholder: { color: 'neutral.4' },
    },
    defaultProps: {
      focusBorderColor: 'action.secondary',
      errorBorderColor: 'danger',
    },
    sizes: {
      md: {
        field: {
          height: '44px',
          borderRadius: '0.5rem',
        },
      },
    },
    variants: {
      outline: {
        field: {
          borderColor: 'neutral.8',

          _hover: {
            borderColor: 'neutral.8',
          },

          _focusVisible: {
            zIndex: 'auto',
            borderColor: 'action.secondary',
            boxShadow: 'var(--chakra-colors-action-secondary) 0px 0px 0px 1px',
          },

          _invalid: {
            borderColor: 'danger',
            boxShadow: 'var(--chakra-colors-danger) 0px 0px 0px 1px',
          },
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      borderRadius: '0.5rem',

      _placeholder: {
        color: 'neutral.4',
      },
    },
    variants: {
      outline: {
        padding: space.deci,
        borderColor: 'neutral.8',

        _hover: {
          borderColor: 'neutral.8',
        },

        _focusVisible: {
          zIndex: 'auto',
          borderColor: 'action.secondary',
          boxShadow: 'var(--chakra-colors-action-secondary) 0px 0px 0px 1px',
        },

        _invalid: {
          borderColor: 'danger',
          boxShadow: 'var(--chakra-colors-danger) 0px 0px 0px 1px',
        },
      },
    },
  },
  Button: {
    baseStyle: {
      '-webkit-tap-highlight-color': 'transparent', // Remove blue outline when tapping on Android
      ...poppinsBaseStyles,
    },
    sizes: {
      lg: {
        ...textStyles.popDuck,
      },
      md: {
        height: '44px',
      },
    },
    variants: {
      solid: {
        borderRadius: 'md',
        color: 'neutral.1.inverse',
        backgroundColor: 'action.primary.base',

        _hover: {
          backgroundColor: 'action.primary.hover',
        },
        _active: {
          backgroundColor: 'action.primary.active',
        },
      },
      link: {
        fontSize: 'inherit',
        lineHeight: 'inherit',
        fontWeight: 600,

        _hover: {
          textDecoration: 'none',
        },
      },
      linkSecondary: (props) => ({
        ...theme.components.Button.variants?.link(props),
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textAlign: 'inherit',
        fontWeight: 600,
        color: 'action.accent',
        whiteSpace: 'normal',

        _hover: {
          textDecoration: 'none',
        },
      }),
      outline: (props) => ({
        ...theme.components.Button.variants?.outline(props),

        ...(props.colorScheme === 'blue'
          ? {
              color: 'action.primary.base',
            }
          : {}),

        borderWidth: '2px',

        _hover: {
          backgroundColor: 'transparent',
        },
      }),
      contextual: {
        whiteSpace: 'normal',
        backgroundColor: 'brand.bg.10',
        textStyle: 'popPuffin',
        color: 'action.primary.base',
        justifyContent: 'left',
        p: 'centi',
        height: 'auto',
        textAlign: 'left',
        display: 'flex',

        _hover: {
          backgroundColor: 'brand.bg.10',
        },
        _active: {
          backgroundColor: 'brand.bg.10',
        },

        '& img': {
          width: '16px',
        },
      },
      // Only on landing
      gradient: {
        color: 'white',
        bgGradient:
          'linear(to-br, var(--chakra-colors-brand-gradient-3-start), var(--chakra-colors-brand-gradient-3-end))',
      },
      gradientOutline: (props) => ({
        ...theme.components.Button.variants?.outline(props),
        // Overlay a transparent white box in the middle over the gradient.
        // This is a workaround to get a gradient border that works with a border radius. See https://gist.github.com/stereokai/36dc0095b9d24ce93b045e2ddc60d7a0
        bgGradient:
          'linear-gradient(neutral.bg.0, var(--chakra-colors-neutral-bg-0)), linear-gradient(135deg, var(--chakra-colors-brand-gradient-2-start) 0%, var(--chakra-colors-brand-gradient-2-end) 100%)',
        bgClip: 'padding-box, border-box',
        backgroundOrigin: 'border-box',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderRadius: 'lg',

        _hover: {
          backgroundColor: 'transparent',
        },
      }),
      // Only on landing
      solidInverted: {
        borderRadius: 'md',
        bgColor: 'white',
        color: 'blue.500',
      },
    },
  },
  Slider: {
    baseStyle: {
      thumb: {
        bg: 'action.primary.base',
        boxShadow: 'none',
      },
    },
  },
  Progress: {
    baseStyle: {
      filledTrack: {
        borderRightRadius: 'md',
        background: 'brand.3',
        transitionProperty: 'width', // https://github.com/chakra-ui/chakra-ui/issues/68#issuecomment-949696853
      },
      track: {
        background:
          'color-mix(in srgb, var(--chakra-colors-neutral-7) 20%, transparent)',
      },
    },
    variants: {
      gradient: {
        filledTrack: {
          bgGradient:
            'linear(to-r, var(--chakra-colors-brand-gradient-2-start), var(--chakra-colors-brand-gradient-2-end))',
        },
      },
    },
  },
  Checkbox: {
    variants: {
      custom: {
        control: {
          visibility: 'hidden',
          position: 'absolute',
        },
        label: {
          margin: 0,
        },
      },
    },
  },
  Radio: {
    sizes: {
      md: {
        control: {
          width: 6,
          height: 6,
        },
        label: {
          ...textStyles.duck,
        },
      },
    },
  },
  Drawer: {
    baseStyle: {
      dialog: {
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingRight: 'env(safe-area-inset-right)',
        paddingLeft: 'env(safe-area-inset-left)',
        // Minor glitch: padding will apply on a horizontal mobile device, where the drawer won’t be fullscreen.
      },
      closeButton: {
        top: 'calc(var(--chakra-space-2) + env(safe-area-inset-top))',
        right: 'calc(var(--chakra-space-3) + env(safe-area-inset-right))',
      },
    },
  },
  Modal: {
    defaultProps: {
      isCentered: true,
    },

    baseStyle: {
      dialogContainer: {
        // Slightly above center
        paddingTop: space.deka,
        paddingBottom: space.mega,

        // On mobile, add some space to the sides around the modal
        paddingX: space.centi,
      },
      dialog: {
        padding: space.deka,
        paddingTop: space.kilo,
      },
      header: {
        marginBottom: space.deka,
        padding: 0,
        color: 'brand.2',
        textAlign: 'center',
        ...textStyles.popFlamingo,
      },
      body: {
        padding: 0,
      },
      footer: {
        padding: 0,
        marginTop: space.hecto,
      },
      closeButton: {
        top: space.deci,
        right: space.deci,
      },
    },

    sizes: {
      full: {
        dialogContainer: {
          padding: 0,
          alignItems: 'flex-start', // Undo isCentered change, which breaks scrollable modals on full.
        },
        dialog: {
          paddingTop: `calc(${space.giga} + env(safe-area-inset-top))`,
          paddingBottom: `calc(${space.mega} + env(safe-area-inset-bottom))`,
          paddingX: space.regular,
        },
        closeButton: {
          top: `calc(${space.deci} + env(safe-area-inset-top))`,
        },
      },
    },

    variants: {
      alert: {
        dialog: {
          textAlign: 'left',
        },
        header: {
          textAlign: 'left',
          marginBottom: 'deci',
          ...textStyles.popSwan,
        },
        footer: {
          padding: 0,
          marginTop: space.hecto,
          gap: space.deci,
        },
      },
    },
  },
  Spinner: {
    baseStyle: {
      color: 'info',
    },
  },
  Select: {
    variants: {
      exposure: (props) =>
        definePartsStyle({
          field: {
            ...theme.components.Select.variants?.outline(props).field,
            padding: 'deci',
            paddingRight: 'kilo',
          },
          icon: {
            color: 'action.primary.base',
            right: 'deci',
          },
        }),
    },
  },
  Badge: {
    baseStyle: {
      textTransform: 'none',
    },
    variants: {
      outline: {
        boxShadow: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 'md',
        ...textStyles.popHummingbird,
      },
    },
  },
};

export default components;
